import React, { Component } from 'react';
import Logo from '../../images/logo.png';
import {Link} from 'react-router-dom'

class Footer extends Component {
    render() {
        const currentYear = new Date().getFullYear();
        return (
            <div className="footer-area text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-menu">
                                <ul className="d-flex " >
                                <li><a target="blank" href="https://www.instagram.com/alena.botox/"><i className="fa fa-instagram"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="footer-sub">
                                <p>Copyright @{currentYear} Design & Developed by <a href="https://ninasavkina.com/" target="_blank">ninasavkina.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
