import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Slides from '../../images/slide-3.jpg';


class Hero extends Component {
    render() {
        return(
            <section id="home" className="hero hero-slider-wrapper hero-style-1">
                <div className="hero-slider">
                    <div className="slide" style={{ backgroundImage: `url(${Slides})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-10 col-sm-12 slide-caption">
                                    <div className="slide-subtitle">
                                        <h4>Мене звати
                                            <br/><b>Альона Ткаченко</b></h4>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Лікар-косметолог</h2>
                                    </div>
                                    <div className="btns">
                                        <a href="https://www.instagram.com/alena.botox/"target="_blank" className="template-btn go-contact-area">Зв'язатись зі мною</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="white_svg">
                    <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                        <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                    </svg>
                </div>
            </section>
        )
    }
}

export default Hero;
