import React from 'react'
import abimg from '../../images/about.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'


const About = (props) => {

    return(
        <section id="about" className="tp-about-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={abimg} alt=""/>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-text">
                        <div className="tp-about-icon-content">
                            <h2>
                                Краса та молодість
                                - бажання кожної жінки.
                                <br/>
                                Я пропоную широкий
                                спектр послуг високої
                                якості.</h2>
                            <p>Мене звати <b>Альона Ткаченко</b>. Я <b>лікар-косметолог</b>, спеціаліст з контурної пластики, медичної естетики та anti age терапії.</p>
                            <p>Займаюсь косметологією та контурною пластикою <b>із 2013 року</b>. За ці 9 років пройшла шлях від звичайних доглядів. Зараз я практикую <b>контурну пластику</b> у повному об’ємі. Мені подобається підбирати препарати індивідуально для кожного паціента. Я вважаю, що мою роботу не повинно бути помітно <b>через 2 тижні</b>, коли препарат повністю вклався.</p>
                            <p>Також я дуже <b>люблю і практикую</b> ботулінотерапію. Це унікальний метод боротьби із мімічними зморшками, який не має аналогів. Не чекайте коли у вас з’являться активні мімічні зморшки. Працювати із ними потрібно на стадії <b>перших проявів</b>, щоб ви зберегли свою міміку і природний вираз обличчя.</p>
                            <p>Рекомендую <b>не економити</b> на собі. Знайти свого лікаря, який відповість на всі ваші запитання. Довіритись йому і продовжувати свою красу і молодість <b>в надійних руках</b> спеціаліста.</p>
                            {/*/!*<div className="signeture">*!/*/}
                            {/*    <span><img src={sign} alt=""/></span>*/}
                            {/*    <p>Ceo Of Designlab IT</p>*/}
                            {/*/!*</div>*!/*/}
                            {/*<DefaultModal buttonClass={'template-btn'}/>*/}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default About;
