import React from 'react';
import ContactForm from '../ContactFrom'
import AnchorLink from "react-anchor-link-smooth-scroll";

const ContactSection = () => {

    return(
        <section id="contact" className="tp-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="info-icon">
                                                <i className="fi flaticon-pin"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Адреса</h2>
                                            <p>вулиця Смілянська, 33</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="info-icon">
                                                <i className="fi flaticon-mail"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Instagram</h2>
                                            <p>@alena.botox</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="info-icon">
                                                 <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Подзвонити</h2>
                                            <p>+380 63 738 55 57</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="section-title section-title2 text-center">
                            <h2>Залишилися питання?</h2>
                            <div className="btns">
                                <a href="https://www.instagram.com/alena.botox/" target="_blank" className="template-btn go-contact-area">Зв'язатись зі мною</a>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="tp-contact-map-section">
                <div className="tp-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1414.3891393547433!2d32.06263283760427!3d49.439364410977944!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14b7901d19011%3A0x8187d164e9192d4d!2z0KHQsNC70L7QvSDQmtGA0LDRgdC-0YLRiyBDaXR5LXo!5e0!3m2!1sru!2sua!4v1643047150902!5m2!1sru!2sua"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default ContactSection;
