import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialImg_1 from '../../images/testimonial/1.jpg';
import testimonialImg_2 from '../../images/testimonial/2.jpg';
import testimonialImg_3 from '../../images/testimonial/3.jpg';
import testimonialImg_4 from '../../images/testimonial/4.jpg';
import testimonialImg_5 from '../../images/testimonial/5.jpg';
import testimonialImg_6 from '../../images/testimonial/6.jpg';
import testimonialImg_7 from '../../images/testimonial/7.jpg';
import testimonialImg_8 from '../../images/testimonial/8.jpg';
import testimonialImg_9 from '../../images/testimonial/9.jpg';
import testimonialImg_10 from '../../images/testimonial/10.jpg';
import testimonialImg_11 from '../../images/testimonial/11.jpg';
import testimonialImg_12 from '../../images/testimonial/12.jpg';

class Testimonial extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 30,
            focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="testimonial-area">
                <div className="container">
                    <div className="testimonial-active">
                        <div className="testimonial-wrap">
                            <Slider {...settings}>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_1} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Анна</h4>
                                            <p>Сьогодні була у Альони на контурній пластиці губ. Я залишилась дуже задоволена! Альона підібрала препарат, який ідеально підійшов. Було зовсім не боляче. Все пройшло дуже швидко. І тепер я із красивими губами. Дуже вам дякую за красу, яку Ви даруєте.</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_2} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Валерія</h4>
                                            <p>Хожу до Альони близько двох років. І для мене кожен візит в радість. Моя шкіра стала ідеальною. Рекомендую 100%</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_3} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Катерина</h4>
                                            <p>Величезне «Дякую» моєму косметологу (була 5.06 робила пілінг PRX) за неймовірний результат. Шкіра стала ніжною і гладенькою, практично ідеальна! Результат видно неозброєним оком</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_4} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Наталія</h4>
                                            <p>Залишилася задоволена обслуговуванням і якістю наданої мені послуги. Лікар люб’язна і тактовна у спілкуванні. Процедура (Ботокс зони очей) безболісна почервоніння і синців не було.</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_5} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Оля</h4>
                                            <p>У Альони робила губи, спочатку боялася, але моя мрія здійснилася, косметолог ідеально зробила природню красу, саме так, як я хотіла. Чоловік почав робити компліменти і взагалі якось оживився))</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_6} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Єкатерина</h4>
                                            <p>Професіонал. Майстер своєї справи. Знаю Альону не один уже рік. Всім подругам рекомендую всі задоволені. Дякую за якісну роботу і красу, що ти даруєш.</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_7} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Світлана</h4>
                                            <p>Чудовий спеціаліст, дуже освідчений і уважний лікар-косметолог. Прийшла з пігментацією на обличчі. За декілька сеансів ситуація значно покращилася. Я дуже задоволена. Тепер ходжу лише до Альони Андріївни.</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_8} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Анна</h4>
                                            <p>Чудовий косметолог! Задоволена на всі 100% Хороша, якісна косметика. Уютний кабінет. Все на вищому рівні!</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_9} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Катя</h4>
                                            <p>У майстра золоті ручки. Все дуже акуратно і стерильно. За одну годину встигли зробити і губи і мезотерапію волосся. Дуже індивідуально і грамотно пояснює свої дії під час процедури. Я залишилася задоволена! Чи буду я ходити ще? БЕЗ СУМНІВІВ</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_10} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Оксана</h4>
                                            <p>Приходила на процедуру збільшення губ і залишилася в повному захваті) відразу видно, що працює спеціаліст. Мені все дуже сподобалося, буду звертатися ще не один раз.</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_11} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Яна</h4>
                                            <p>Являюсь клієнткою Альони вже декілька років і хочу сказати, що вона золотий майстер своєї справи. Із великого спектру послуг особисто я робила мезонитки для укріплення нижньої третини обличчя. Результат перевершив всі мої очікування. Овал підтягнувся і став чіткішим, рада своєму відображенні в дзеркалі.</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <div className="testimonial-img">
                                            <img src={testimonialImg_12} alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <h4>Ніна</h4>
                                            <p>Мій улюблений і єдиний косметолог. Раніше я була в пошуку найкращого спеціаліста. Тепер я визначилася, вона найкраща. Завдяки їй моя шкіра в гарному стані. Я дуже задоволена! Косметолог від Бога!!!❤️</p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="white_svg">
                    <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                        <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                    </svg>
                </div>
            </div>




        );
    }
}

export default Testimonial;
