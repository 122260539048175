
import React, { Fragment } from 'react';


import {Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import'./style.css'


const ServiceSingle = ({ maxWidth, open, onClose, title, serviceId, doc,image1,image2,image3}) => {

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <i className="fa fa-close"></i>
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const Blocks = [
        {
            id: "1",
            firstParagraph: "Техніка контурингу губ підбирається лікарем-косметологом на попередній консультації. Вибір техніки за контурної пластики залежить від бажаної форми губ, необхідності виправлення контуру та наявності асиметрії. За допомогою корекції можна отримати губи різної форми.",
            secondParagraph: "На консультації потрібно сказати лікарю-косметологу, як саме ви хочете змінити свої губи. Лікар також зможе порадити форму, яка підійде вам найкраще (враховуються форма та овал обличчя, відстань від носа до підборіддя).",
        },
        {
            id: "2",
            firstParagraph: "Ліполітики для обличчя та тіла рекомендуються при локальних відкладеннях жиру у проблемних зонах: друге підборіддя, боки, ноги, живіт, сідниці та ін. Видимий результат буде навіть у тих місцях, які не піддаються корекції за допомогою дієти та спорту.",
            secondParagraph: "Ліполітики для тіла та обличчя ефективно розчиняють жири при їх локальному заляганні, нормалізують обмін речовин. Позитивно впливають на стан шкіри, зменшують товщину жирового прошарку та допомагають зробити силует більш підтягнутим та струнким.",
        },
        {
            id: "3",
            firstParagraph: "Ботокс і Диспорт - це лікарські препарати на основі ботулінічного токсину, які вводяться в мімічні м'язи і тимчасово знерухомлюють їх, завдяки чому шкіра стає більш рівною, відбувається розгладження зморшок. Ботокс і Диспорт виробляються різними компаніями і виходять за допомогою різних способів синтезу, але по ефекту на мімічні м'язи, є препаратами-аналогами. Обидва препарати вводяться за допомогою ін'єкцій.",
            secondParagraph: "Ефект від уколів настає не відразу, а на 2-5 день. Поступово пацієнт помічає, що йому складно звести брови, складно здивуватися, при посмішці не закладаються зморшки. Повна дія препарату розкривається через 2 тижні і тоді ж можлива докорекція.",
        },
        {
            id: "4",
            firstParagraph: "Контурна пластика – процедура з омолодження та корекції обличчя шляхом підшкірних ін'єкцій натуральних гелеподібних препаратів (філерів) на основі гіалуронової кислоти. Остання виступає природним компонентом тканин, тому безпечно вирівнює складки та зморшки, об'ємно заповнює потрібні ділянки. Так як гіалуронова кислота біосумісна з клітинами організму, вона з часом засвоюється і поступово розсмоктується.",
            secondParagraph: "\n" +
                "До переваг процедури відносять її безболісність та відсутність хірургічного втручання для підтягнення обличчя. Після неї одразу можна вести звичний спосіб життя, тому відсутній період реабілітації. Також неабияким плюсом пластики за допомогою ін’єкцій є відсутність шрамів, хоча ефект не поступається результатам від пластичної хірургії.",
        },
        {
            id: "5",
            firstParagraph: "Зрозуміти, чим відрізняється біоревіталізація від мезотерапії можна, детально ознайомившись з кожною з цих процедур.  Мезотерапія передбачає введення в середину і підшкірно біологічно активних речовин. Вона також відома як «ін’єкції краси». До складу препаратів для мезотерапії входять біологічно-активні речовини, вітаміни, мікроелементи. Також є комплексні препарати з гіалуроновою кислотою.\n" +
                "\n" +
                "Введення препаратів частіше відбувається підшкірно — цим процедура також відрізняється від біоревиталізації, де введення проводять всередину шкіри. Головна мета мезотерапії — поліпшити кровотворення в обраній зоні і стимулювати виробництво колагену і еластину. Мезотерапія з’явилася в косметології дещо раніше, ніж біоревіталізація.",
            secondParagraph: "Різниця між мезотерапією і біоревіталізацією полягає в речовині, яка вводиться всередину шкіри. Якщо в першому випадку — це різні речовини, то біоревіталізація передбачає введення гіалуронової кислоти. Вона виробляється в організмі людини і входить до складу епітелію, нервових волокон, а також сполучної тканини. Вважається, що візуальні прояви старіння шкіри пов’язані саме з недоліком синтезу в організмі гіалуронової кислоти.\n" +
                "\n" +
                "На відміну від мезотерапії, біоревіталізація вважається більш делікатною процедурою, адже всередину шкіри вводиться не чужорідна речовина, а знайома організму молекула.\n" +
                "\n" +
                "Не можна сказати, що біоревіталізація ефективніша або краща. Результат кожної процедури залежить від індивідуальних особливостей організму.",
        },
        {
            id: "6",
            firstParagraph: "Мезонитки — це anti-age процедура для обличчя (рідше — для тіла), яка легко вирішує проблеми зів’ялої та атонічної шкіри. Тредліфтинг за допомогою мезониток здійснює підтяжку та укріплення шкіри за допомогою «каркасу», що установлюється в різних шарах дерми (так зване армування). Він не викликає дискомфорту, до того ж, з часом розсмоктується — втім, ефект 3d-підтяжки зберігається надовго.",
            secondParagraph: "За допомогою голки-провідника під шкіру вводиться мезонитка. Після цього голка виймається, залишаючи нитку в тканинах. Приблизна тривалість процедури постановки мезониток — від 30 хвилин до 1,5 години. Пацієнту можуть зробити знеболення за допомогою нанесення спеціального крему. В середньому вводять 20-30 мезониток. До речі, ці препарати бувають різними, їх вид і кількість вибирає лікар-косметолог в залежності від індивідуального стану шкіри пацієнта.",
        },
    ]

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={onClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body">
                    <div className="tp-minimals-wrap">
                        <div className="minimals-img">
                            <img src={image1} alt="" />
                        </div>
                    </div>
                    <div>
                        {Blocks.filter(block => serviceId === block.id).map((block, blo) => (
                            <div className="service-details-section" key={blo}>
                                <p>{doc}</p>
                                <p>{block.firstParagraph}</p>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12 mb-3">
                                        <div className="service-details-img">
                                            <img src={image2} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 mb-3">
                                        <div className="service-details-img">
                                            <img src={image3} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <p>{block.secondParagraph}</p>
                            </div>
                        ))}
                    </div>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default ServiceSingle

