import React, { useState } from 'react';
import ServiceSingle from '../ServiceSingle'
import { Button } from '@material-ui/core'
import lips from '../../images/lips.svg'
import lipolytics from '../../images/lipolytics.svg'
import botox from '../../images/botox.svg'
import plastic from '../../images/plastic.svg'
import mesotherapy from '../../images/mesotherapy.svg'
import mesothreads from '../../images/mesothreads.svg'

import s1 from '../../images/service-single/web-design/img-1.jpg'
import s2 from '../../images/service-single/web-design/img-2.jpg'
import s3 from '../../images/service-single/web-design/img-3.jpg'

import d1 from '../../images/service-single/development/img-1.jpg'
import d2 from '../../images/service-single/development/img-2.jpg'
import d3 from '../../images/service-single/development/img-3.jpg'

import c1 from '../../images/service-single/creative/img-1.jpg'
import c2 from '../../images/service-single/creative/img-2.jpg'
import c3 from '../../images/service-single/creative/img-3.jpg'

import r1 from '../../images/service-single/responsive/img-1.jpg'
import r2 from '../../images/service-single/responsive/img-2.jpg'
import r3 from '../../images/service-single/responsive/img-3.jpg'

import b1 from '../../images/service-single/branding/img-1.jpg'
import b2 from '../../images/service-single/branding/img-2.jpg'
import b3 from '../../images/service-single/branding/img-3.jpg'

import sp1 from '../../images/service-single/support/img-1.jpg'
import sp2 from '../../images/service-single/support/img-2.jpg'
import sp3 from '../../images/service-single/support/img-3.jpg'

const Service = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state,setState] = useState({
    })

    const handleClickOpen = (item) =>{
        setOpen(true);
        setState(item)
    }
    const service = [
        {
            Id:"1",
            icons:lips,
            heading:"Збільшення губ",
            Simg1:s1,
            Simg2:s2,
            Simg3:s3,
            des:'Процедура збільшення губ, це унікальна методика, що дозволяє покращити форму губ, а також скорегувати форму, але при цьому залишаються дуже природніми. Процедура є дуже ефективною та безпечною. Форма губ стає красивою, шкіра зволожується та розгладжується. Результат зберігається до 12 місяців.'
        },
        {
            Id:"2",
            icons:lipolytics,
            heading:"Ліполітики",
            Simg1:d1,
            Simg2:d2,
            Simg3:d3,
            des:'Процедура ідеально підходить для боротьби із локальними жировими відкладеннями. Якщо вас турбують «вушка» на стегнах, живіт або бока, які не зникають навіть при схудненні- ця процедура ідеально підійде для вас. Усього за декілька сеансів ви отримаєте фігуру своєї мріі.'
            
        },
        {
            Id:"3",
            icons:botox,
            heading:"Ботокс/Диспорт",
            Simg1:c1,
            Simg2:c2,
            Simg3:c3,
            des:'Світовий золотий стандарт для роботи із зморшками на лобі, в зоні міжбрів‘я та біля очей. Усього за один сеанс ми вирішимо цю проблему. Також за допомогою ботулотоксина можна припідняти кути рота, і досягти ліфтингового ефекту в нижній третині обличчя. Ефект зберігається 4-6 місяців.'
        },
        {
            Id:"4",
            icons:plastic,
            heading:"Контурна пластика обличчя",
            Simg1:r1,
            Simg2:r2,
            Simg3:r3,
            des:'Процедура робить обличчя більш гармонійним, повертає шкірі колишню пружність і колір. А також корегує асиметрію або вікові зміни обличчя. Не потребує тривалої реабілітації. Ефект зберігається до 24 місяців'
        },
        {
            Id:"5",
            icons:mesotherapy,
            heading:"Мезотерапія/Біоревіталізація",
            Simg1:b1,
            Simg2:b2,
            Simg3:b3,
            des:'За допомогою мезококтейлів та біоревіталізантів ми працюємо над якістю шкіри. Спектр роботи надзвичайно широкий. Починаючи від вугрів та пост акне і закінчуючи пігментацією та дрібними зморшками. Впливає на будь-яку ділянку тіла, в тому числі на волосся'
        },
        {
            Id:"6",
            icons:mesothreads,
            heading:"Мезонитки",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Унікальна процедура, що являється альтернативою коловій підтяжці обличчя. За допомогою мезониток можна підняти брови і досягти ефекту «лисячих очей» або позбутися нечіткого контуру обличчя, без операційних втручань. Ефект зберігається до 2 років.'
        },
    ]

    return (
        <div id="service" className="service-area section-padding">
            <div className="borderd"></div>
            <div className="container">
                <div className="col-l2">
                    <div className="section-title section-title2 text-center">
                        <h2>Мої послуги</h2>
                    </div>
                </div>
                <div className="row">
                    {service.map((serv, srv) => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={srv}>
                            <div className="service-section">
                                <div className="services-wrapper">
                                    <div className="services-icon-wrapper">
                                        <img className="services-icon" src={serv.icons}/>
                                    </div>
                                    <div className="service-content">
                                        <h2>{serv.heading}</h2>
                                        <p>{serv.des}</p>
                                        <Button
                                            className="btn"
                                            onClick={()=> handleClickOpen(serv)}>
                                            Дивитись далі
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                </div>
            </div>
            <div className="white_svg">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
            <ServiceSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} serviceId={state.Id} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/>
        </div>
    );
}
export default Service;
