import pimg1 from '../images/project/img-1.jpg'
import pimg2 from '../images/project/img-2.jpg'
import pimg3 from '../images/project/img-3.jpg'
import pimg4 from '../images/project/img-4.jpg'
import pimg5 from '../images/project/img-5.jpg'
import pimg6 from '../images/project/img-6.jpg'
import pimg7 from '../images/project/img-7.jpg'
import pimg8 from '../images/project/img-8.jpg'
import pimg9 from '../images/project/img-9.jpg'
import pimg10 from '../images/project/img-10.jpg'
import pimg11 from '../images/project/img-11.jpg'
import pimg12 from '../images/project/img-12.jpg'
import pimg13 from '../images/project/img-13.jpg'
import pimg14 from '../images/project/img-14.jpg'
import pimg15 from '../images/project/img-15.jpg'

import ps1img1 from '../images/project-single/img-1.jpg'
import ps1img2 from '../images/project-single/img-2.jpg'
import ps1img3 from '../images/project-single/img-3.jpg'

import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       ps1img:ps1img1,
    },
    {
       Id:'2',
       pImg:pimg2,
       ps1img:ps1img2,
    },
    {
      Id:'3',
      pImg:pimg3,
      ps1img:ps1img3,
   },
   {
      Id:'4',
      pImg:pimg4,
      ps1img:ps1img2,
   },
    {
       Id:'5',
       pImg:pimg5,
       ps1img:ps1img1,
    },
    {
       Id:'6',
       pImg:pimg6,
       ps1img:ps1img2,
    },
    {
      Id:'7',
      pImg:pimg7,
      ps1img:ps1img3,
   },
   {
      Id:'8',
      pImg:pimg8,
      ps1img:ps1img2,
   },
    {
       Id:'9',
       pImg:pimg9,
       ps1img:ps1img1,
    },
    {
       Id:'10',
       pImg:pimg10,
       ps1img:ps1img2,
    },
    {
      Id:'11',
      pImg:pimg11,
      ps1img:ps1img3,
   },
   {
      Id:'12',
      pImg:pimg12,
      ps1img:ps1img2,
   },
    {
       Id:'13',
       pImg:pimg13,
       ps1img:ps1img1,
    },
    {
       Id:'14',
       pImg:pimg14,
       ps1img:ps1img2,
    },
    {
      Id:'15',
      pImg:pimg15,
      ps1img:ps1img3,
   },
   
    
]

export default Projects;
