import React, { useState } from 'react';
import './PriceMobile.module.css';
import classes from './PriceMobile.module.css';
import {Grid, Button} from '@material-ui/core';

const data = [
    {
        name: "Збільшення губ",
        options: [
            {name: "Neuramis (1 ml)", cost:"3700"},
            {name: "Sardenya (1,1 ml)", cost:"3700"},
            {name: "Rejeuness (1,1 ml)", cost:"3700"},
            {name: "Stylage М (1 ml)", cost:"4700"},
            {name: "Juvederm Smile (0.55 ml)", cost:"4400"},
            {name: "Juvederm 2 (0.5 ml)", cost:"3900"},
            {name: "Juvederm 3 (1 ml)", cost:"5300"},

            {name: ".", cost: '.'},
            {name: "Видалення старого філлера", cost:"1300"},

        ]
    },
    {
        name: "Ліполітики",
        options: [
            {name: "Зменшення носу:", cost:"."},
            {name: "Light Fit", cost:"2000"},
            {name: ".", cost: '.'},
            {name: "Обличчя:", cost:"."},
            {name: "Face Nade", cost:"900"},
            {name: "Metabolites + Lipotrofin", cost:"900"},
            {name: "Light Fit", cost:"1700"},
            {name: "Mesosculpt", cost:"3700"},
            {name: "Тріада", cost:"3200"},
            {name: ".", cost: '.'},
            {name: "Тіло:", cost:"."},
            {name: "Pressensa", cost:"900"},
            {name: "Тріада", cost:"3200"},
            {name: ".", cost: '.'},
        ]
    },
    {
        name: "Ботулотоксин",
        options: [
            {name: "Botulax", cost:"."},
            {name: ".", cost: '.'},
            {name: "Лоб", cost:"1300"},
            {name: "Очі", cost:"1800"},
            {name: "Міжбрів’я", cost:"1800"},
            {name: "Зморшки носу", cost:"1200"},
            {name: "Кисетні зморшки ", cost:"1200"},
            {name: "Ліфтинг Ніфертіті", cost:"4000"},
            {name: ".", cost: '.'},
            {name: "Dysport", cost:"."},
            {name: ".", cost: '.'},
            {name: "Лоб", cost:"1700/2200"},
            {name: "Очі", cost:"1800"},
            {name: "Міжбрів’я", cost:"2200/2700"},
            {name: "Ліфтинг Ніфертіті", cost:"6000"},
            {name: "Гіпергідроз ", cost:"6000"},
        ]
    },
    {
        name: "Контурна пластика обличчя",
        options: [
            {name: "Нососльозні борозди:", cost:"."},
            {name: "Neuramis", cost:"3700"},
            {name: "Juvederm Volbella", cost:"6100"},
            {name: "Носогубні зморшки", cost:"3700"},
            {name: "Скули:", cost:"."},
            {name: "Neuramis Volume (2 ml) ", cost:"5200"},
            {name: "Juvederm Voluma (2 ml)", cost:"9900"},
            {name: "Кути Джолі (2 ml)", cost:"5200"},
            {name: "Підборіддя", cost:"3900"},
        ]
    },
    {
        name: "Мезотерапія/Біоревіталізація",
        options: [
            {name: "Мезотерапія волосся", cost:"600"},
            {name: "Мезотерапія обличчя", cost:"1200"},
            {name: "Мезотерапія очі", cost:"1200"},
            {name: "Hyaron ", cost:"1300"},
            {name: "Aquashine Classic", cost:"3100"},
            {name: "Aquashine BR", cost:"3100"},
            {name: "Aquashine BTX", cost:"3500"},
            {name: "Neuramis meso ", cost:"2500"},
            {name: "Meso-Wharton", cost:"3500"},
            {name: "Meso-Xanthin", cost:"3500"},
            {name: "Mesoeye", cost:"3500"},
            {name: "Belotero Soft бланшинг", cost:"4800"},
            {name: "PRX t33", cost:"1400"},
            {name: "Мезороллер", cost:"400"},


        ]
    },
    {
        name: "Мезонитки",
        options: [
            {name: "Коги", cost:"2500"},
            {name: "Лисячі очі", cost:"4000"},
            {name: "Підтяжка брів", cost:"4000"},
            {name: "Моно нитки (10 шт) ", cost:"1800"},
            {name: "Нитки Скрю, Торнадо (10 шт) ", cost:"2300"},
        ]
    },
];

function PriceMobile() {
    const [item, setItem] = useState(0);
    return (
        <React.Fragment>
            <div className="pricing-body">
                <div className="container">
                    <Grid container justify={'center'}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Grid container direction="row" justify={"left"} alignContent={"center"} xs={12} spacing={1} className={classes.PriceButtons}>
                                {data.map((item, index) => (
                                    <Grid item ><Button variant="outlined" onClick={() => setItem(index)}>{item.name}</Button></Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                    </Grid>
                    <Grid container justify={'center'} alignItems={"center"} className={classes.PriceItems}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Grid container direction={"column"} justify={"center"} alignContent={"left"} className={classes.PriceItemsList}>
                                {data[item].options.map((item, index) => (
                                    <Grid container  direction={"row"} justify={"space-between"} alignContent={"left"}  className={classes.PriceItemsListEntry}>
                                        {("" + item.name) === '.' ?
                                            <Grid item className={classes.PriceTextHidden}>{item.name}</Grid>
                                            : <Grid item className={classes.PriceText}>{item.name}</Grid>}
                                        {("" + item.cost) === '.' ?
                                            <Grid item className={classes.PriceTextHidden}>{item.cost} </Grid>
                                            : <Grid item className={classes.PriceTextAmount}>{item.cost} </Grid>}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PriceMobile;
